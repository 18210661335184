import styled from '@emotion/styled'
import { Divider, spacing, Text, WidgetBase } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
export type WidgetCompanyActivityProps = {
  title: string
  href: string | undefined
  classification:
    | {
        type: string | null
        code: string | null
        description: string | null
      }
    | undefined
  declaredActivity: string | null | undefined
  isLoading?: boolean
}

export const WidgetCompanyActivity = function (props: WidgetCompanyActivityProps): ReactElement {
  const { title, href, classification, declaredActivity, isLoading = false } = props

  if (classification === undefined && declaredActivity === undefined && isLoading === false) {
    return <WidgetBase title={title} href={href} />
  }

  return (
    <WidgetBase title={title} href={href} isBodyLoading={isLoading}>
      <div>
        <StyledRow>
          <Text variant="body2Medium">Code {classification?.type ? classification.type : 'NAF'}</Text>
          {classification ? (
            <Text variant="body2">
              {classification.code ? classification.code : undefined} (
              {classification.description ? classification.description : undefined})
            </Text>
          ) : undefined}
        </StyledRow>
        <Divider orientation="horizontal" />
        <StyledRow>
          <Text variant="body2Medium">Activité principale déclarée</Text>
          {declaredActivity ? <Text variant="body2">{declaredActivity}</Text> : undefined}
        </StyledRow>
      </div>
    </WidgetBase>
  )
}

const StyledRow = styled.div`
  padding-top: ${spacing[30]};
  padding-bottom: ${spacing[30]};
`
