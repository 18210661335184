import styled from '@emotion/styled'
import { Divider, Text, WidgetBase } from '@orus.eu/pharaoh'
import { Fragment, type ReactElement } from 'react'
import { EntityRow } from './entity-row'

export type EntityCompany = {
  entityType: 'company'
  name: string
  placeOfRegistration: string | null
  registrationNumber: string | null
}

export type EntityIndividual = {
  entityType: 'individual'
  firstName: string | null
  lastName: string | null
  birthDate: string | null
  birthCountry: string | null
  nationalityCountry: string | null
  ownershipPercentage?: number | null
  isBeneficialOwner: boolean
}

export type ComplyAdvantageCheck = {
  customerIdentifier: string
  href: string | undefined
  totalItems: number
  stageType: 'need_review' | 'in_review' | 'is_reviewed' | 'no_case'
  decisionType: 'approved' | 'rejected' | null
}

export type Entity = {
  entityId: string
  check: ComplyAdvantageCheck | undefined
} & (EntityCompany | EntityIndividual)

export type WidgetEntityProps = {
  title: string
  href: string | undefined
  entities: Entity[] | null
  isLoading?: boolean
  isReadOnly?: boolean
}

export const WidgetEntity = function (props: WidgetEntityProps): ReactElement {
  const { title, href, entities, isLoading = false, isReadOnly = false } = props

  if (!entities && isLoading === false) {
    return <WidgetBase title={title} href={href} />
  }

  if (entities && entities.length === 0) {
    return (
      <WidgetBase title={title} href={href}>
        <StyledEmptyEntities>
          <Text variant="body2">
            Il n&apos;y a pas de {title === 'Dirigeants' ? 'dirigeant' : 'bénéficiaire effectif'}
          </Text>
        </StyledEmptyEntities>
      </WidgetBase>
    )
  }

  return (
    <WidgetBase title={title} href={href} isBodyLoading={isLoading}>
      {entities &&
        entities.map((entity, index) => (
          <Fragment key={entity.entityId}>
            <EntityRow entity={entity} isReadOnly={isReadOnly} />
            {index < entities.length - 1 && <Divider orientation="horizontal" />}
          </Fragment>
        ))}
    </WidgetBase>
  )
}

const StyledEmptyEntities = styled.div`
  text-align: center;
`
