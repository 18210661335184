import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { File } from '@orus.eu/frontend/src/components/pages/subscription-v2/elements/documents-list-element/types'
import { Chip, spacing } from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo, type ReactNode } from 'react'
import { LABEL_AND_VARIANT_PER_FILE_STATUS } from '../../../../subscription-v2/elements/documents-list-element/chip-config'
import type { DocumentGroupStatus } from '../../detailed-sheet.types'
import DocumentButton from './DocumentButton'
import EmptyDocumentList from './EmptyDocumentList'

type DocumentsGridListProps = {
  title: ReactNode
  groupStatus: DocumentGroupStatus[]
  onFileClick: (selectedFile: File) => void
}

const DocumentsGridList = memo(function DocumentsGridList(props: DocumentsGridListProps) {
  const { title, groupStatus, onFileClick } = props

  const files = groupStatus.flatMap(({ files }) => files)

  return (
    <DocumentGridWrapper
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[40]};
      `}
    >
      <Row variant="descriptive" title={title} />
      {files.length > 0 ? (
        <DocumentsGridContainer>
          {files.map((file) => (
            <div key={file.id}>
              <DocumentButton
                key={file.id}
                title={file.title}
                avatar={
                  <Chip variant={LABEL_AND_VARIANT_PER_FILE_STATUS[file.status ?? 'empty'].variant} dark size="large">
                    {LABEL_AND_VARIANT_PER_FILE_STATUS[file.status ?? 'empty'].label}
                  </Chip>
                }
                onClick={() => onFileClick(file)}
              />
            </div>
          ))}
        </DocumentsGridContainer>
      ) : (
        <EmptyDocumentList />
      )}
    </DocumentGridWrapper>
  )
})

export default DocumentsGridList

const DocumentGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
  margin-bottom: ${spacing[100]};
`
const DocumentsGridContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${spacing[30]};
  margin: 0 auto;
`
