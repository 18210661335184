import styled from '@emotion/styled'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { assert } from '@orus.eu/error'
import {
  Badge,
  Button,
  Divider,
  Icon,
  SaveablePage,
  spacing,
  Tab,
  Tabbar,
  useAsyncCallback,
  useDialogVisibility,
  useEnqueueToast,
} from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { isFailure } from '@orus.eu/result'
import { useParams, useSearch } from '@tanstack/react-router'
import { memo, useCallback, useEffect, useMemo, useRef, useState, type ReactElement } from 'react'
import { useReactToPrint } from 'react-to-print'
import { trpcReact } from '../../../../client'
import { getCollectedFileDownloadLink } from '../../../../lib/download-links'
import { useNavigateBack } from '../../../../lib/hooks/use-navigate-back'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { useOpenHubspotContact } from '../../../../lib/hooks/use-open-hubspot-contact'
import { usePermissions } from '../../../../lib/use-permissions'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { LABEL_PER_DOCUMENT_CATEGORY } from '../../subscription-v2/elements/documents-list-element/labels'
import type { File } from '../../subscription-v2/elements/documents-list-element/types'
import { FileViewerDialog, type ViewerFile } from '../common/FileViewerDialog'
import { Timeline } from '../common/timeline/Timeline'
import {
  detailedSheetCompleteStatusBadgeVariantColor,
  detailedSheetCompleteStatusText,
} from '../utils/detailed-sheet-common'
import { ActivitySection } from './components/ActivitySection'
import { CompanySection } from './components/CompanySection'
import { ExperienceSection } from './components/ExperienceSection'
import { InsuranceHistorySection } from './components/InsuranceHistorySection'
import { OtherSection } from './components/OtherSection'
import { RepresentativeSection } from './components/RepresentativeSection'
import { SummarySection } from './components/SummarySection'
import { useDetailedSheetState } from './detailed-sheet-state'
import type { ComplianceData, DetailedSheetSectionType } from './detailed-sheet.types'
import { getComplianceData } from './helpers/get-compliance-data'
import { getCurrentSiren } from './helpers/get-current-siren'

const TabsLabels: Record<DetailedSheetSectionType, string> = {
  representative: 'Représentant',
  company: 'Entreprise',
  activity: 'Activité',
  experience: 'Expérience',
  'insurance-history': "Historique d'assurance",
  conversation: 'Conversation',
  other: 'Autre',
}

const DetailedSheetPage = memo(function DetailedSheetPage(): ReactElement {
  const { subscriptionId, versionId, endorsementId } = useParams({ strict: false })
  const { timeline_id: timelineId, thread_id: threadId, tab_id: tabId } = useSearch({ strict: false })
  assert(subscriptionId, 'missing subscriptionId url param')
  const componentRef = useRef(null)
  const { openHubspotContact } = useOpenHubspotContact()
  const { enqueueToast } = useEnqueueToast()
  const permissions = usePermissions()

  const isReadOnly = !!versionId && !!subscriptionId

  const navigateBack = useNavigateBack()

  const { changes, setChanges, before, after, commit, committing } = useDetailedSheetState({
    subscriptionId,
    versionId,
    endorsementId,
  })

  const [isComplianceDataLoading, setIsComplianceDataLoading] = useState(false)
  const viewerDialog = useDialogVisibility('detailed-sheet-viewer')
  const [selectedFileId, setSelectedFileId] = useState<string | undefined>(undefined)
  const [selectedCategory, setSelectedCategory] = useState<GroupCategory | 'none' | undefined>(undefined)
  const [showAcag, setShowAcag] = useState(false)

  const initialAcagComment = useMemo(() => {
    return before?.detailedSheetAcagSynthesis != null && before?.detailedSheetAcagSynthesis !== ''
  }, [before])

  useEffect(() => {
    if (initialAcagComment) {
      setShowAcag(true)
    }
  }, [initialAcagComment])

  const state = Object.assign({}, after, changes)

  const userType = usePermissions().type

  const isPlatform = userType === 'platform'

  const hasChanges = Object.keys(changes).length > 0

  //#Management of the compliance data

  const siren = getCurrentSiren(before, changes)
  const fetchComplianceDataQuery = trpcReact.compliance.fetchAllComplianceData.useQuery({ siren }, { enabled: false })
  const lastComplianceDataQuery = trpcReact.compliance.getLastComplianceData.useQuery(
    { siren, subscriptionId, versionId },
    { enabled: !!siren },
  )

  const onClickRefreshCompliance = useAsyncCallback(async () => {
    if (!siren || !fetchComplianceDataQuery) return
    const complianceDataResult = await fetchComplianceDataQuery.refetch()
    if (complianceDataResult.isSuccess) {
      const { pappersCompany, lystaCompany, complyAdvantageCustomersCase } = complianceDataResult.data

      const failedCases = complyAdvantageCustomersCase.filter(isFailure)

      if (isFailure(pappersCompany) || isFailure(lystaCompany) || failedCases.length > 0) {
        enqueueToast('Erreur lors de la récupération des données', {
          variant: 'danger',
        })
      } else {
        enqueueToast('Données récupérées avec succès', {
          variant: 'success',
        })
      }
    } else {
      enqueueToast("Erreur lors de l'analyse des données", { variant: 'danger' })
    }
  }, [enqueueToast, fetchComplianceDataQuery, siren])

  useEffect(() => {
    if (fetchComplianceDataQuery.isLoading || lastComplianceDataQuery.isLoading) {
      setIsComplianceDataLoading(true)
    } else {
      setIsComplianceDataLoading(false)
    }
  }, [fetchComplianceDataQuery.isLoading, lastComplianceDataQuery.isLoading])

  const complianceData: ComplianceData =
    fetchComplianceDataQuery && fetchComplianceDataQuery.data
      ? getComplianceData(fetchComplianceDataQuery.data)
      : getComplianceData(lastComplianceDataQuery.data)

  //#endregion

  //#Management of documents interactions
  //TODO : Refactor to implement a hook to get the files and the groups
  const collectedFiles = trpcReact.collectedFile.getAllFiles.useQuery({ subscriptionId })
  const filesPerCategory = Object.values(collectedFiles.data?.files ?? {}).reduce<
    Partial<Record<GroupCategory | 'none', File[]>>
  >((acc, file) => {
    if (file.deleted) return acc
    const groupCategory = (file.groupId ? collectedFiles.data?.groups[file.groupId]?.category : 'none') ?? 'none'
    acc[groupCategory] = (acc[groupCategory] ?? []).concat(file)
    return acc
  }, {})

  const allFilesSortedByCategory = useMemo(() => {
    const fileIndexPerCategory: Map<GroupCategory | 'none', number> = new Map()
    return Object.values(collectedFiles.data?.files ?? {})
      .flatMap<ViewerFile>((file) => {
        if (file.deleted) return []
        const group = file.groupId ? collectedFiles.data?.groups[file.groupId] : undefined
        const fileIndexForCategory = fileIndexPerCategory.get(group?.category ?? 'none') ?? 1
        fileIndexPerCategory.set(group?.category ?? 'none', fileIndexForCategory + 1)

        return [
          {
            uri: getCollectedFileDownloadLink(file.id),
            fileType: file.mimeType,
            metadata: {
              file,
              fileIndexForCategory,
              group: group ?? null,
            },
          },
        ]
      })
      .sort((a, b) => {
        const aCategory = a.metadata.group?.category ?? 'none'
        const bCategory = b.metadata.group?.category ?? 'none'
        if (aCategory !== bCategory) {
          return aCategory < bCategory ? -1 : 1
        }
        return a.metadata.fileIndexForCategory - b.metadata.fileIndexForCategory
      })
  }, [collectedFiles.data?.files, collectedFiles.data?.groups])

  const selectedFile = useMemo(() => {
    return allFilesSortedByCategory.find((file) => file.metadata.file.id === selectedFileId)
  }, [allFilesSortedByCategory, selectedFileId])

  const statusPerCategory = useMemo(
    () =>
      Object.values(collectedFiles.data?.groups ?? {}).reduce<Partial<Record<GroupCategory | 'none', GroupStatus>>>(
        (acc, group) => {
          const groupCategory = group.category ?? 'none'
          acc[groupCategory] = group.status
          return acc
        },
        {},
      ),
    [collectedFiles],
  )

  useEffect(() => {
    const indexOfSelectedCategory = allFilesSortedByCategory.findIndex(
      (file) => file.metadata.group?.category === selectedCategory,
    )
    setSelectedFileId(allFilesSortedByCategory[indexOfSelectedCategory]?.metadata.file.id)
  }, [allFilesSortedByCategory, selectedCategory])

  const handleCloseFileViewerDialog = () => {
    viewerDialog.hide()
  }

  const handleSelectedCategoryChange = (category: GroupCategory) => {
    setSelectedCategory(category)
    const indexOfSelectedCategory = allFilesSortedByCategory.findIndex(
      (file) => file.metadata.group?.category === category,
    )
    setSelectedFileId(allFilesSortedByCategory[indexOfSelectedCategory]?.metadata.file.id)
    viewerDialog.show()
  }

  const handleFileClick = (file: File) => {
    setSelectedFileId(file.id)
    viewerDialog.show()
  }

  //#endregion

  //#Management of navigation
  const navigateToPendingSubscriptions = useNavigateTo({
    to: `/bak/v2-pending-subscriptions/$subscriptionId`,
    params: { subscriptionId },
    search: {
      tab_id: 'supporting-documents',
    },
  })
  const navigateToEndorsement = useNavigateTo({
    to: `/bak/contracts/$subscriptionId/endorsement/$endorsementId`,
    params: { subscriptionId, endorsementId },
    search: {
      tab_id: 'supporting-documents',
    },
  })

  const handleBack = useCallback(() => {
    if (versionId) {
      navigateBack()
      return
    }

    // Endorsement in progress
    if (endorsementId) {
      navigateToEndorsement()
    } else {
      navigateToPendingSubscriptions()
    }
  }, [versionId, endorsementId, navigateToEndorsement, navigateToPendingSubscriptions, navigateBack])

  const [selectedTabId, setSelectedTabId] = useState<string>('representative')

  const handleTabChange = (tabId: string) => {
    setSelectedTabId(tabId)
  }
  //#endregion

  //#Management Hubspot opeining

  const currentEmail = changes.email || before?.email || undefined

  //#endregion

  //#Management of the PDF download

  // TODO : Use it to download file
  const handleAfterPrint = useCallback(() => {}, [])

  // TODO : Use it to make user wait for the download to finish
  const handleBeforePrint = useCallback(() => {
    return Promise.resolve()
  }, [])

  const printAsPdf = useReactToPrint({
    contentRef: componentRef,
    documentTitle: 'Fiche détaillée',
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  })

  const handleDownload = useCallback(() => {
    printAsPdf()
  }, [printAsPdf])
  //#endregion

  //#Management of the tabs selection
  const { detailedSheetExperienceStatus } = state

  const [filteredTabsIds, setFilteredTabsIds] = useState(Object.keys(TabsLabels))

  useEffect(() => {
    if (detailedSheetExperienceStatus === 'pioupiou' || detailedSheetExperienceStatus === 'poussin') {
      setFilteredTabsIds(Object.keys(TabsLabels).filter((key) => key !== 'insurance-history'))
    } else {
      setFilteredTabsIds(Object.keys(TabsLabels))
    }
  }, [detailedSheetExperienceStatus, setFilteredTabsIds])
  //#endregion

  //#Management of ACAG
  const onRemoveAcagClick = useCallback(() => {
    setSelectedTabId('activity')
    setShowAcag(false)
    setChanges({ detailedSheetAcagSynthesis: '' })
  }, [setChanges])

  const onAddAcagClick = useCallback(() => {
    setSelectedTabId('activity')
    setShowAcag(true)
  }, [])

  const activityIdsFileGroupStatus = [
    {
      label: LABEL_PER_DOCUMENT_CATEGORY['proformaInvoice'],
      category: 'proformaInvoice' as GroupCategory,
      status: statusPerCategory.proformaInvoice ?? 'empty',
      files: filesPerCategory.proformaInvoice ?? [],
    },
  ]

  if (showAcag) {
    activityIdsFileGroupStatus.push({
      label: LABEL_PER_DOCUMENT_CATEGORY['acag'],
      category: 'acag' as GroupCategory,
      status: statusPerCategory.acag ?? 'empty',
      files: filesPerCategory.acag ?? [],
    })
  }
  //#endregion

  if (!before) return <GlobalLoadingState />

  const isCurrentTab = (tab: DetailedSheetSectionType) => {
    return tab === selectedTabId && filteredTabsIds.includes(tab)
  }

  const hasUnsavedChanges = Object.keys(changes).length > 0

  return (
    <SaveablePage hasUnsavedChanges={hasUnsavedChanges}>
      {selectedFile && viewerDialog.visible ? (
        <FileViewerDialog
          selectedFile={selectedFile}
          onSelectedFileChange={(file) => setSelectedFileId(file.metadata.file.id)}
          allFiles={allFilesSortedByCategory}
          totalForCategory={filesPerCategory[selectedFile.metadata.group?.category ?? 'none']?.length ?? 0}
          allowedCategories={state.neededSubscriptionDocuments ?? []}
          close={handleCloseFileViewerDialog}
          onReviewFinished={null}
          invalidate={() => {
            void collectedFiles.refetch()
          }}
        />
      ) : null}

      <LayoutContainer>
        <HeaderContainer>
          <Header
            title={'Fiche détaillée'}
            badge={
              <Badge
                variant={detailedSheetCompleteStatusBadgeVariantColor[before?.detailedSheetCompleteStatus ?? 'pending']}
                dark
              >
                {detailedSheetCompleteStatusText[before?.detailedSheetCompleteStatus ?? 'pending']}
              </Badge>
            }
            leftButton={
              <Button
                icon="arrow-left-regular"
                size="small"
                variant="secondary"
                onClick={handleBack}
                ariaLabel="Retour"
                disabled={committing}
              />
            }
            rightButtonGroups={[
              <ButtonGroup key="detailed-sheet-buttons">
                <Button
                  key="download-button"
                  title="Télécharger la fiche détaillée"
                  variant="secondary"
                  size="small"
                  icon="arrow-down-to-line-regular"
                  onClick={handleDownload}
                />
                <Button
                  key="refresh-compliance-button"
                  title={siren ? 'Rafraîchir les données' : 'Rajoutez le siren pour rafraîchir les données'}
                  variant="secondary"
                  size="small"
                  onClick={onClickRefreshCompliance}
                  icon="arrows-rotate-regular"
                  disabled={
                    !siren || fetchComplianceDataQuery.isLoading || lastComplianceDataQuery.isLoading || isReadOnly
                  }
                  ariaLabel="Rafraîchir les données"
                />
                {isPlatform ? (
                  <Button
                    key="hubspot-button"
                    title="Hubspot"
                    variant="secondary"
                    size="small"
                    onClick={() => openHubspotContact(currentEmail)}
                    icon="hubspot-brands"
                  />
                ) : (
                  <></>
                )}
                <Button
                  key="save-button"
                  variant="primary"
                  size="small"
                  disabled={!hasChanges || isReadOnly || committing}
                  isLoading={committing}
                  onClick={commit}
                >
                  Sauvegarder
                </Button>
              </ButtonGroup>,
            ]}
          />
        </HeaderContainer>
        <Divider orientation="horizontal" />
        <BodyContainer>
          <ContentContainer>
            <ContentWrapper>
              <div ref={componentRef}>
                <SummarySection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  quote={before.quote}
                  groupStatusByCategory={statusPerCategory}
                  filesPerCategory={filesPerCategory}
                  onIdFileGroupStatusClick={handleSelectedCategoryChange}
                  complianceData={complianceData}
                  onFileClick={handleFileClick}
                  showAcag={showAcag}
                  isReadOnly={isReadOnly}
                />
              </div>
              <div>
                <TabbarContainer>
                  <Tabbar size="large" activeTabId={selectedTabId} onClick={handleTabChange}>
                    {Object.entries(TabsLabels)
                      .filter(([key]) => key !== 'conversation' || permissions.permissions.includes('timeline.read'))
                      .map(([key, label]) => (
                        <Tab tabId={key} key={key}>
                          {label}
                        </Tab>
                      ))}
                  </Tabbar>

                  {!showAcag && !isReadOnly ? (
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={onAddAcagClick}
                      avatar={<Icon icon="plus-regular" />}
                      avatarPosition="left"
                    >
                      Commentaire ACAG
                    </Button>
                  ) : (
                    <></>
                  )}
                  {showAcag && !isReadOnly ? (
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={onRemoveAcagClick}
                      avatar={<Icon icon="trash-can-regular" />}
                      avatarPosition="left"
                    >
                      Retirer commentaire ACAG
                    </Button>
                  ) : (
                    <></>
                  )}
                </TabbarContainer>

                <Divider orientation="horizontal" />

                {isCurrentTab('representative') ? (
                  <RepresentativeSection
                    changes={changes}
                    stateAfter={after}
                    state={state}
                    stateBefore={before}
                    setChanges={setChanges}
                    subscriptionId={subscriptionId}
                    idsFileGroupStatus={[
                      {
                        label: LABEL_PER_DOCUMENT_CATEGORY['id'],
                        category: 'id' as GroupCategory,
                        status: statusPerCategory.id ?? 'empty',
                        files: filesPerCategory.id ?? [],
                      },
                    ]}
                    onIdFileGroupStatusClick={handleSelectedCategoryChange}
                    complianceData={complianceData}
                    isComplianceDataLoading={isComplianceDataLoading}
                    onFileClick={handleFileClick}
                    isReadOnly={isReadOnly}
                  />
                ) : isCurrentTab('company') ? (
                  <CompanySection
                    changes={changes}
                    stateAfter={after}
                    state={state}
                    stateBefore={before}
                    setChanges={setChanges}
                    subscriptionId={subscriptionId}
                    idsFileGroupStatus={[
                      {
                        label: LABEL_PER_DOCUMENT_CATEGORY['immatriculation'],
                        category: 'immatriculation' as GroupCategory,
                        status: statusPerCategory.immatriculation ?? 'empty',
                        files: filesPerCategory.immatriculation ?? [],
                      },
                      {
                        label: LABEL_PER_DOCUMENT_CATEGORY['RIB'],
                        category: 'RIB' as GroupCategory,
                        status: statusPerCategory.RIB ?? 'empty',
                        files: filesPerCategory.RIB ?? [],
                      },
                    ]}
                    onIdFileGroupStatusClick={handleSelectedCategoryChange}
                    complianceData={complianceData}
                    isComplianceDataLoading={isComplianceDataLoading}
                    onFileClick={handleFileClick}
                    isReadOnly={isReadOnly}
                  />
                ) : isCurrentTab('activity') ? (
                  <ActivitySection
                    changes={changes}
                    stateAfter={after}
                    state={state}
                    stateBefore={before}
                    setChanges={setChanges}
                    subscriptionId={subscriptionId}
                    idsFileGroupStatus={activityIdsFileGroupStatus}
                    onIdFileGroupStatusClick={handleSelectedCategoryChange}
                    complianceData={complianceData}
                    isComplianceDataLoading={isComplianceDataLoading}
                    onFileClick={handleFileClick}
                    showAcagComment={showAcag}
                    isReadOnly={isReadOnly}
                  />
                ) : isCurrentTab('experience') ? (
                  <ExperienceSection
                    changes={changes}
                    stateAfter={after}
                    state={state}
                    stateBefore={before}
                    setChanges={setChanges}
                    subscriptionId={subscriptionId}
                    idsFileGroupStatus={[
                      {
                        label: LABEL_PER_DOCUMENT_CATEGORY['experience'],
                        category: 'experience' as GroupCategory,
                        status: statusPerCategory.experience ?? 'empty',
                        files: filesPerCategory.experience ?? [],
                      },
                    ]}
                    onFileClick={handleFileClick}
                    isReadOnly={isReadOnly}
                  />
                ) : isCurrentTab('insurance-history') ? (
                  <InsuranceHistorySection
                    changes={changes}
                    stateAfter={after}
                    state={state}
                    stateBefore={before}
                    setChanges={setChanges}
                    subscriptionId={subscriptionId}
                    idsFileGroupStatus={[
                      {
                        label: LABEL_PER_DOCUMENT_CATEGORY['infoReport'],
                        category: 'infoReport' as GroupCategory,
                        status: statusPerCategory.infoReport ?? 'empty',
                        files: filesPerCategory.infoReport ?? [],
                      },
                      {
                        label: LABEL_PER_DOCUMENT_CATEGORY['decennaleCertificate'],
                        category: 'decennaleCertificate' as GroupCategory,
                        status: statusPerCategory.decennaleCertificate ?? 'empty',
                        files: filesPerCategory.decennaleCertificate ?? [],
                      },
                    ]}
                    onIdFileGroupStatusClick={handleSelectedCategoryChange}
                    isReadOnly={isReadOnly}
                  />
                ) : isCurrentTab('conversation') && permissions.permissions.includes('timeline.read') ? (
                  <Timeline
                    subscriptionId={subscriptionId}
                    timelineId={timelineId}
                    threadId={threadId}
                    tabId={tabId}
                    timelineHeight="600px"
                  />
                ) : isCurrentTab('other') ? (
                  <OtherSection
                    changes={changes}
                    stateAfter={after}
                    state={state}
                    stateBefore={before}
                    setChanges={setChanges}
                    subscriptionId={subscriptionId}
                    idsFileGroupStatus={[
                      {
                        label: LABEL_PER_DOCUMENT_CATEGORY['other'],
                        category: 'other' as GroupCategory,
                        status: statusPerCategory.other ?? 'empty',
                        files: filesPerCategory.other ?? [],
                      },
                    ]}
                    onIdFileGroupStatusClick={handleSelectedCategoryChange}
                    onFileClick={handleFileClick}
                  />
                ) : null}
              </div>
            </ContentWrapper>
          </ContentContainer>
          <Divider orientation="vertical" className="divider" />
        </BodyContainer>
      </LayoutContainer>
    </SaveablePage>
  )
})

export default DetailedSheetPage

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: none;
`

const ContentWrapper = styled.div`
  padding: ${spacing[60]};
  max-width: 1440px;
  gap: ${spacing[60]};
  display: flex;
  flex-direction: column;
`

const LayoutContainer = styled.div`
  flex: 1;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  display: flex;
`

const BodyContainer = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  overflow: auto;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
  padding: ${spacing[60]};
`

const TabbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
