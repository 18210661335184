import styled from '@emotion/styled'
import { formatDdMmYyyy, parseYyyyMmDd } from '@orus.eu/calendar-date'
import { Divider, spacing, Text, WidgetBase } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'

export type WidgetCompanyInformationProps = {
  title: string
  href: string | undefined
  name: string | undefined | null
  commercialName: string | undefined | null
  legalForm: string | undefined | null
  registrationDate: string | undefined
  isLoading?: boolean
}

export const WidgetCompanyInformation = function (props: WidgetCompanyInformationProps): ReactElement {
  const { title, href, name, commercialName, legalForm, registrationDate, isLoading = false } = props

  if (!name && !commercialName && !legalForm && !registrationDate && isLoading === false) {
    return <WidgetBase title={title} href={href} />
  }

  return (
    <WidgetBase title={title} href={href} isBodyLoading={isLoading}>
      <div>
        <StyledRow>
          <StyledColumn>
            <Text variant="body2Medium">Date de création</Text>
            <Text variant="body2">
              {registrationDate ? formatDdMmYyyy(parseYyyyMmDd(registrationDate)) : undefined}
            </Text>
          </StyledColumn>
          <StyledColumn>
            <Text variant="body2Medium">Raison sociale</Text>
            <Text variant="body2">{name === null ? '-' : name}</Text>
          </StyledColumn>
        </StyledRow>
        <Divider orientation="horizontal" />
        <StyledRow>
          <StyledColumn>
            <Text variant="body2Medium">Forme juridique</Text>
            <Text variant="body2">{legalForm}</Text>
          </StyledColumn>
          <StyledColumn>
            <Text variant="body2Medium">Nom commercial</Text>
            <Text variant="body2">{commercialName === null ? '-' : commercialName}</Text>
          </StyledColumn>
        </StyledRow>
      </div>
    </WidgetBase>
  )
}

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${spacing[30]};
  padding-bottom: ${spacing[30]};
`

const StyledColumn = styled.div`
  width: 50%;
`
