import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import { spaceBetweenFormFields } from '@orus.eu/pharaoh'
import type { ReactElement, ReactNode } from 'react'
import { useMemo } from 'react'

export type FormFieldsContainerProps = {
  sx?: SxProps
  children: ReactNode
}

export function FormFieldsContainer({ sx, children }: FormFieldsContainerProps): ReactElement {
  const passedSx: SxProps = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: spaceBetweenFormFields,
      ...sx,
    }),
    [sx],
  )

  return <Box sx={passedSx}>{children}</Box>
}
