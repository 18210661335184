import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Badge,
  Button,
  spacing,
  Tooltip,
  useAsyncCallback,
  useEnqueueTemporaryNotificationAlert,
} from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows/row'
import { isSuccess } from '@orus.eu/result'
import { useState, type ReactElement } from 'react'
import { trpcReact } from '../../../../../../client'
import { displayName } from './helper/display-name'
import { getComplyAdvantageRiskColor } from './helper/get-comply-check-color'
import { getComplyAdvantageCheckTooltip } from './helper/get-comply-check-tooltip'
import { getExternalLink } from './helper/get-external-link'
import type { ComplyAdvantageCheck, Entity } from './widget-entity'

export type EntityRowProps = {
  entity: Entity
  isReadOnly?: boolean
}

export const EntityRow = function (props: EntityRowProps): ReactElement {
  const { entity, isReadOnly } = props
  const { enqueueTemporaryNotificationAlert } = useEnqueueTemporaryNotificationAlert()
  const [check, setCheck] = useState<ComplyAdvantageCheck | undefined>(entity.check)

  const refreshComplyAdvantageQuery =
    entity.check?.customerIdentifier &&
    trpcReact.compliance.refreshComplyAdvantageCase.useQuery(
      { customerIdentifier: entity.check.customerIdentifier, entityId: entity.entityId },
      { enabled: false },
    )

  const onClickRefreshComplyAdvantage = useAsyncCallback(async () => {
    if (!entity.check?.customerIdentifier || !entity.entityId || !refreshComplyAdvantageQuery) return
    const result = await refreshComplyAdvantageQuery.refetch()
    if (result.isSuccess) {
      enqueueTemporaryNotificationAlert('Les risques ont été mis à jour', { variant: 'success' })
      if (result.data && isSuccess(result.data) && result.data.output?.customerIdentifier) {
        setCheck({
          ...result.data.output,
          href: getExternalLink(result.data.output.customerIdentifier, 'complyadvantage'),
        })
      } else {
        setCheck(undefined)
      }
    }
  }, [
    enqueueTemporaryNotificationAlert,
    entity.check?.customerIdentifier,
    entity.entityId,
    refreshComplyAdvantageQuery,
  ])

  return (
    <StyledEntityRow>
      <Row
        css={css`
          width: 100%;
        `}
        title={displayName(entity)}
        titleVariant="body2"
        subtitle={entity.entityType === 'individual' && entity.isBeneficialOwner ? entity.birthDate : undefined}
        href={entity.check?.href}
        openInNewTab={true}
        size="small"
        badge={
          <StyledBadge>
            {entity.entityType === 'individual' && entity.ownershipPercentage && entity.isBeneficialOwner ? (
              <Badge dark={false} variant="gray">
                {entity.ownershipPercentage}%
              </Badge>
            ) : (
              <Badge dark variant="gray">
                {entity.entityType === 'company' ? 'Soc.' : 'Ind.'}
              </Badge>
            )}
            {check ? (
              <Tooltip title={getComplyAdvantageCheckTooltip(check)}>
                <Badge
                  dark={getComplyAdvantageRiskColor(check).dark}
                  variant={getComplyAdvantageRiskColor(check).variant}
                  minWidth="20px"
                >
                  {check.totalItems}
                </Badge>
              </Tooltip>
            ) : (
              <></>
            )}
          </StyledBadge>
        }
      />
      {entity.check?.customerIdentifier && !isReadOnly ? (
        <Button
          variant="text"
          style="decorative"
          onClick={onClickRefreshComplyAdvantage}
          icon="arrows-rotate-regular"
          size="small"
          disabled={refreshComplyAdvantageQuery ? refreshComplyAdvantageQuery.isLoading : false}
        />
      ) : (
        <></>
      )}
    </StyledEntityRow>
  )
}

const StyledBadge = styled.div`
  display: flex;
  gap: ${spacing['30']};
`

const StyledEntityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${spacing['30']};
`
