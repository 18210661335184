import styled from '@emotion/styled'
import { spacing, Text } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { FormFieldsContainer } from '../../../../../templates/form-fields-container'
import type { DetailedSheetSectionType } from '../../detailed-sheet.types'

type CommonSectionProps = {
  title?: ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
  onChange: (value: string) => void
  value: string | null | undefined
  defaultTable?: DetailedSheetSectionType
}

const CommonSection = memo(function CommonSection({ title, leftContent, rightContent }: CommonSectionProps): ReactNode {
  return (
    <CommonSectionContainer>
      {title ? <Text variant="body1Medium">{title}</Text> : null}
      <FormFieldsContainer>
        <CommonSectionFormContainer hasRightContent={!!rightContent}>
          <CommonSectionFormLeftContainer>{leftContent}</CommonSectionFormLeftContainer>
          {rightContent ? <CommonSectionFormRightContainer>{rightContent}</CommonSectionFormRightContainer> : null}
        </CommonSectionFormContainer>
      </FormFieldsContainer>
    </CommonSectionContainer>
  )
})

const CommonSectionContainer = styled.div`
  display: grid;
  flex-direction: column;
  gap: ${spacing[60]};
  margin: ${spacing[60]} 0 ${spacing[100]};
`
const CommonSectionFormContainer = styled.div<{ hasRightContent: boolean }>`
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr ${({ hasRightContent }) => (hasRightContent ? '325px' : '0')};
  gap: ${({ hasRightContent }) => (hasRightContent ? spacing[60] : '0')};
`
const CommonSectionFormLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`
const CommonSectionFormRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`

export default CommonSection
